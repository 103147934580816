<template>
  <div id="links-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link v-if="!pending" to="/scraper" class="btn btn-primary mb-5">Zum Fullsite-Scraper</router-link>

    <div v-if="pending" class="card p-5 w-75 mx-auto mt-4 text-center">
      <i class="fad fa-cloud-download fa-5x text-primary"></i>
      <h3 class="mt-4">
        Scraper lädt diese Seite bereits.
      </h3>
      <p>
        Komm später wieder oder schreibe eine E-Mail an
        <a href="mailto:alexander@fuerst.one">alexander@fuerst.one</a>.
      </p>
      <div>
        <button @click="reset" class="btn btn-primary mt-4">
          Zurück
        </button>
      </div>
    </div>
    <div v-else-if="forbidden" class="card p-5 w-75 mx-auto mt-4 text-center">
      <i class="fad fa-exclamation-circle fa-5x text-warning"></i>
      <h3 class="mt-4">
        Dein Kontingent für diese Seite ist erschöpft. <br>
      </h3>
      <p>
        Komm {{ nextSingleScrape }} wieder oder schreibe eine E-Mail an
        <a href="mailto:alexander@fuerst.one">alexander@fuerst.one</a>.
      </p>
      <div>
        <button @click="reset" class="btn btn-primary mt-4">
          Zurück
        </button>
      </div>
    </div>

    <div v-else>
      <div v-if="step < 2" class="card w-75 mx-auto">
        <div class="card-header">
          <h3 class="my-0">Neuer Einzelseiten-Scrape</h3>
        </div>
        <div class="card-body">
          <h4>Um die weiteren Funktionen der App nutzen zu können, müssen wir deine Seite auslesen.</h4>
          <p>
            Die folgenden Module bauen alle darauf auf, dass Screenshots und Texte deiner Seite innerhalb der App
            verfügbar sind. Damit du wichtige Metriken zur Lesbarkeit deiner Texte aber auch die Screenshots der Seiten
            für sogenannte "Audits" benutzen kannst, müssen wir daher deine Seite auslesen.
            <br><br>
            Sobald die App den Leseprozess - auch "Scraping" genannt - durchlaufen ist, hast du alle benötigten Daten
            um weiterzumachen. Folge einfach den Anweisungen, um die neuen Funktionen freizuschalten.
          </p>

          <div class="row justify-content-center mt-5">
            <div class="col-sm-6">
              <h5>Wie lautet die URL, die du scrapen willst?</h5>
              <p class="small mb-2">Beginnend mit <code>http://</code> oder <code>https://</code></p>
              <input type="text" v-model="url" class="form-control">
            </div>
          </div>

          <div class="text-center">
            <button v-if="step === 0"
                    @click="prepareDownload"
                    class="btn btn-primary mt-4"
                    :disabled="!isValidUrl"
            >
              Weiter
            </button>
          </div>

          <div v-if="step === 1">
            <div class="text-center my-5">
              <h5 class="mt-4">Nun müssen wir nur noch sicherstellen, dass du nur deine eigene Website scrapst.</h5>
              <p>
                Lade dazu einfach diese Datei auf das Root-Verzeichnis deines Servers hoch.
              </p>
              <div @click="download($event)" class="btn btn-primary">
                <span v-if="!loading">Approval-Datei downloaden</span>
                <span v-else><i class="fad fa-circle-notch fa-spin"></i></span>
              </div>
              <a id="approval-file-download" style="display:none"></a>
              <p class="small mt-3">
                <strong>Hinweis:</strong> Ändere nicht den Dateinamen. Der Scraper sucht nach
                <code>{{ approval.root_url }}/scraping-approval.json</code>
              </p>
            </div>

            <div v-if="fileDownloaded" class="alert alert-success">
              <h4 class="h5">Sobald alles erledigt ist, klicke auf den Button unten, um den Scraper zu starten.</h4>
              <p>
                <strong>Wichtig:</strong> Solltest du diese Seite neu aufgerufen haben, musst du deine Approval-Datei neu
                herunterladen und auf deinem Server überschreiben. Mit jedem Aufruf der Seite wird eine neue, einzigartige ID
                erzeugt, die bestätigt, dass der Scraping-Vorgang aus dieser App stammt.
              </p>
              <button @click="runScraper" class="btn btn-block btn-success">Scraper anfordern</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 2" class="card w-75 mx-auto">
        <div class="card-header">
          <h3>
            Einzelseiten-Scrape freigegeben
          </h3>
        </div>
        <div class="card-body">
          <h4>Gut gemacht!</h4>
          <p>
            Dein Scrape wurde freigegeben. Stelle sicher, dass die Datei in den nächsten 10min hochgeladen
            wird, dass der Scraper sie lesen kann. <br>
          </p>
          <h5>Zu deiner Info:</h5>
          <p>
            Dieser Prozess wird in kommenden Versionen deutlich verbessert. Freu dich schon auf weitere Funktionen und
            noch mehr hilfreiche Einstellungs- und Auslesemöglichkeiten.
          </p>
        </div>
        <div class="card-footer">
          <router-link to="/links" class="btn btn-block btn-primary">Zurück zum Link Tree</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ScraperSingle',
  components: {},
  data() {
    return {
      step: 0,
      loading: true,
      pending: false,
      forbidden: false,
      fileDownloaded: false,
      url: "",
      approval: {
        id: "",
        date: "",
        root_url: "",
        path: "",
        content_selector: "",
        scrape_excludes: [],
        language: "english",
        size: 10,
        max_depth: 4,
        sleep: 2,
        status: "approved"
      },
      depthMapping: {
        1000: 1,
        500: 2,
        100: 3,
        0: 4,
      },
    }
  },
  computed: {
    nextSingleScrape() {
      if (this.url.split("/").length > 2) {
        let urlSplit = this.url.split("/");
        let rootUrl = urlSplit.slice(0,3).join("/");
        let path = "/" + urlSplit.slice(3).join("/");

        let lastApproval = this.project.scraper.single.find(a => {
          return a.root_url === rootUrl && a.path === path
        });

        let daysUntil = this.nextSingleApproval(lastApproval);

        return daysUntil === 1 ? 'morgen' : ('in ' + daysUntil + ' Tagen');
      } else {
        return "";
      }
    },
    isValidUrl() {
      return this.url.match(
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
      );
    }
  },
  watch: {
    url(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.step = 0;
      }
    }
  },
  methods: {
    prepareDownload() {
      this.step++;

      let urlSplit = this.url.split("/");
      this.approval.root_url = urlSplit.slice(0,3).join("/");
      this.approval.path = "/" + urlSplit.slice(3).join("/");

      let lastApproval = this.project.scraper.single.find(a => {
        return a.root_url === this.approval.root_url && a.path === this.approval.path
      });

      if (lastApproval) {
        if (lastApproval.status === 'pending') {
          this.pending = true;
        } else if (this.nextSingleApproval(lastApproval) > 0) {
          this.forbidden = true;
        }
      } else {
        setTimeout(() => {
          this.loading = false
        }, 500);
      }
    },
    download($event) {
      $event.stopPropagation();
      $event.preventDefault();
      this.loading = true;

      let urlSplit = this.url.split("/");
      this.approval.root_url = urlSplit.slice(0,3).join("/");
      this.approval.path = "/" + urlSplit.slice(3).join("/");

      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.approval));
      let dlAnchorElem = document.getElementById('approval-file-download');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "scraping-approval.json");
      dlAnchorElem.click();

      console.info(this.approval);

      setTimeout(() => {
        this.loading = false;
        this.fileDownloaded = true;
      }, 1000);

      return false;
    },
    reset() {
      this.url = this.project.profile.rootUrl;
      this.loading = true;
      this.pending = false;
      this.forbidden = false;
      this.fileDownloaded = false;
    },
    runScraper() {
      this.project.scraper.single.push(this.approval);
      this.$store.dispatch("project/initiateScraper", {type: "single", approval: this.approval})
          .then(() => {
            this.initiatingScraper = false;
            this.step++;
          });
    }
  },
  beforeMount() {
    this.approval.id = this.generateId();
    this.approval.date = new Date().toJSON();
    this.approval.root_url = this.project.profile.rootUrl;
    this.url = this.project.profile.rootUrl;
  },
  mounted() {
  },
}
</script>
